import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LayoutHome from '../components/Home/layoutHome'
import SEO from '../components/seo'
import HomeTwoColCopyLeft from '../components/Home/homeTwoColCopyLeft'
import HomeTwoColCopyRight from '../components/Home/homeTwoColCopyRight'
import HomeTwoColCopyLeftTwo from '../components/Home/homeTwoColCopyLeftTwo'
import HomeLogos from '../components/Home/homeLogos'
import BiosFeature from '../components/Home/homeBios'
import HomeOfferings from '../components/Home/homeOfferings'
import VideoModalHome from '../components/videoModalHome'

const IndexPage = ({data}) => (
  <LayoutHome>
    <SEO 
      title="LifeSync | Leading Source for Medical Cables and Leadwires" 
      description="LifeSync specializes in creating high-quality, cost-effective cables and leadwires for cardiac and neuro monitoring, as well as custom cable manufacturing."
      ogimg="https://www.lifesync.com/static/hero-home-p-68887cab881dc915bb49debf1d67f892.jpg"
      link="https://www.lifesync.com"
    />
    <div className="homeIntro">
      <Container>
        <Row>
          <Col>
            <h2>Experts in patient monitoring connections</h2>
            <p>LifeSync is the only vertically-integrated company completely focused on patient connections, and is your single-source provider of cardiac cables, leadwires and neuromonitoring connections, as well as contract manufacturing. You’ll find the perfect mix of high-quality connections and cost-effective prices, ideal for the needs of patient care professionals, procurement departments, and device manufacturers.</p>
          </Col>
        </Row>
      </Container>
    </div>
    <HomeTwoColCopyLeftTwo />
    <HomeTwoColCopyRight />
    <HomeTwoColCopyLeft />
    <HomeLogos />
    <BiosFeature />
    <Container className="videoLauncher">
      <Row>
        <Col>
          <VideoModalHome />
        </Col>
      </Row>
    </Container>
    <HomeOfferings />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.38 17.79" className="bgIcon"><title>LifeSync Icon</title><g id="Layer_2" data-name="Layer 2"><g id="Page_03" data-name="Page 03"><polygon className="cls-1" points="16.97 10.49 9.69 17.77 3.16 11.24 3.92 10.48 9.69 16.26 15.47 10.47 16.97 10.49 16.97 10.49"/><polygon className="cls-1" points="16.97 10.46 9.69 17.73 3.16 11.2 3.92 10.44 9.69 16.22 15.47 10.44 16.97 10.45 16.97 10.46"/><polygon className="cls-1" points="16.97 10.51 9.69 17.79 3.16 11.26 3.92 10.5 9.69 16.27 15.47 10.49 16.97 10.51 16.97 10.51"/><path className="cls-2" d="M1.66,9.7a5.68,5.68,0,0,1,4-9.7A5.64,5.64,0,0,1,8.86,1l-.77.77A4.62,4.62,0,0,0,2.4,8.94L9.69,1.65h0A5.69,5.69,0,0,1,18,9.41c-1.54,0-3.1,0-4.65,0l-1-3.71L9.91,14.17,8.57,7.94,7,9.54H4.82l1-1h.75L9.18,6l.93,4.27L12.38,2l1.73,6.42,3.3,0a4.67,4.67,0,0,0,.9-2.76,4.61,4.61,0,0,0-7.87-3.26l-8,8Z"/></g></g></svg>
  </LayoutHome>
)

export default IndexPage
