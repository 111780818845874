import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const HomeTwoColCopyLeft = () => (
  <div className="homeTwoColCopyLeft">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftCopy">
                <Row>
                    <Col>                        
                        <div className="copyLeftWrap">
                            <h2>A leader in quality</h2>
                            <p>LifeSync manufactures cables and monitoring connections in-house to the highest industry standards, fully-tested, and certified.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 2}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/product-banner-1.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="20"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default HomeTwoColCopyLeft
