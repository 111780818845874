import React, { useState } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import VideoPlayHome from '../images/lifesync-corporate-video-thumb.jpg'

function VideoModalHome() {
    const [show, setShow] = useState(false);
  
    return (
      <>
        <Button variant="primary" onClick={() => setShow(true)} className="videoLauncherBtn">
          <img src={VideoPlayHome} alt="LifeSync Corporate Video" title="LifeSync Corporate Video" loading="lazy" className="img-fluid" />
        </Button>
  
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              &nbsp;
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/ursb7u2MQos?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="VideoOverlay" allowfullscreen></iframe></div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
export default VideoModalHome
