import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax';

const HomeTwoColCopyLeftTwo = () => (
  <div className="homeTwoColCopyLeftTwo">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftCopy">
                <Row>
                    <Col>
                        <div className="copyLeftWrap">
                            <h2>Cost-effective<br />
                            without compromise</h2>
                            <p>LifeSync is the economical, single-source alternative to expensive OEM connections from multiple suppliers.  </p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 2}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/ls-cost-effective.jpg')}
                    bgImageAlt="LifeSync Cost Effective"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default HomeTwoColCopyLeftTwo
