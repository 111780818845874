import React from "react"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import CardiacIcon from '../../images/icon-cardiac-white.svg'
import NeuroIcon from '../../images/icon-neuro-white.svg'
import SourceIcon from '../../images/icon-source-white.svg'

const HomeHeroNav = () => (
  <section className="homeHeroNav">
    <a href="/cardiac/" className="item">
        <Row>
            <Col xs={3} sm={3} lg={4} className="imageWrap">
                <img src={CardiacIcon} alt="LifeSync Cardiac" title="LifeSync Cardiac" loading="lazy" />
            </Col>
            <Col xs={9} sm={9} lg={8} className="copyWrap">
                <h2>LifeSync Cardiac</h2>
                <p>Cardiac monitoring cables with unparalleled radiolucency and signal quality.</p>
            </Col>
        </Row>
    </a>
    <a href="https://www.rochestersuperstore.com/" className="item" target="_blank" rel="noopener noreferrer">
        <Row>
            <Col xs={3} sm={3} lg={4} className="imageWrap">
                <img src={NeuroIcon} alt="LifeSync Neuro" title="LifeSync Neuro" loading="lazy" />
            </Col>
            <Col xs={9} sm={9} lg={8} className="copyWrap">
                <h2>LifeSync Neuro</h2>
                <p>Unmatched quality, cost, and selection of neurodiagnostic and neuromonitoring connections.</p>
            </Col>
        </Row>
    </a>
    <a href="/source/" className="item">
        <Row>
            <Col xs={3} sm={3} lg={4} className="imageWrap">
                <img src={SourceIcon} alt="LifeSync Source" title="LifeSync Source" loading="lazy" />
            </Col>
            <Col xs={9} sm={9} lg={8} className="copyWrap">
                <h2>LifeSync Source</h2>
                <p>Custom design, manufacturing, and fulfillment of medical cables and connections.</p>
            </Col>
        </Row>
    </a>
  </section>
)

export default HomeHeroNav
