import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ABLogo from '../../images/ab-logo.png'
import AMCLogo from '../../images/amc-logo.png'
import RochesterLogo from '../../images/rochester-logo.png'
import VitalLogo from '../../images/logo-vital-connections.png'

const HomeLogos = () => (
  <div className="homeLogos">
    <Container>
        <Row>
            <Col s={12} lg={5} className="heading">
                <Row>
                    <Col>
                        <h2>The LifeSync family of companies is trusted by the world's leading patient care providers and manufacturers.</h2>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={7} className="logos">
                <Row>
                    <Col xs={6} className="logoItem">
                        <a href="https://americanbiosurgical.com/" target="_blank" rel="noopener noreferrer">
                            <img src={ABLogo} alt="3M" width="" className="American Biosurgical" title="3M" loading="lazy" />
                        </a>
                    </Col>
                    <Col xs={6} className="logoItem">
                        <a href="http://store.advantagemed.com/index.php?page=products" target="_blank" rel="noopener noreferrer">
                            <img src={AMCLogo} alt="Abbott" width="" className="AMC" title="Abbott" loading="lazy" />
                        </a>
                    </Col>
                    <Col xs={6} className="logoItem">
                        <a href="https://www.rochestersuperstore.com/" target="_blank" rel="noopener noreferrer">
                        <   img src={RochesterLogo} alt="Bay Care Medical Group" width="" className="logoRochester" title="Bay Care Medical Group" loading="lazy" />
                        </a>
                    </Col>
                    <Col xs={6} className="logoItem">
                        <a href="https://vitalconnect.com/" target="_blank" rel="noopener noreferrer">
                            <img src={VitalLogo} alt="Vital Connections" width="" className="logoVital" title="Vital Connections" loading="lazy" />
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  </div>
)

export default HomeLogos
