import React, { useState } from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { FaPlus, FaMinus } from 'react-icons/fa'

import HeadShotBobBC from '../../images/headshot-bob-buehler.jpg'
import HeadShotBobBBW from '../../images/headshot-bob-buehler-bw.jpg'
import HeadShotIanWC from '../../images/headshot-ian-west.jpg'
import HeadShotIanWBW from '../../images/headshot-ian-west-bw.jpg'
import HeadShotBobZC from '../../images/headshot-bob-zinsmaster.jpg'
import HeadShotBobZBW from '../../images/headshot-bob-zinsmaster-bw.jpg'
import HeadShotPedroTC from '../../images/headshot-pedro-tejeda.jpg'
import HeadShotPedroTBW from '../../images/headshot-pedro-tejeda-bw.jpg'
import HeadShotYuliaRC from '../../images/headshot-yulia-varabei.jpg'
import HeadShotYuliaRBW from '../../images/headshot-yulia-varabei-bw.jpg'
import HeadShotJoshSC from '../../images/headshot-josh-stapleton.jpg'
import HeadShotJoshRBW from '../../images/headshot-josh-stapleton-bw.jpg'

const bios = [
    {
        bioname:'Bob Buehler',
        biotitle:'Chief Executive Officer',
        biotitleHover:'Chief Executive Officer',
        headshot: HeadShotBobBBW,
        headshotAlt: 'Bob Buehler - Chief Executive Officer',
        headshotHover: HeadShotBobBC,
        headshotHoverAlt: 'Bob Buehler - Chief Executive Officer',
        bio:'Bob Buehler is the Chief Executive Officer. Bob previously held senior executive positions at two high-growth private equity-backed companies and was actively involved in a number of liquidity events. For over twenty-five years, he held senior operating and international business roles at global healthcare companies including RespirTech/Philips Healthcare, 3M Healthcare, and Arizant Healthcare. Bob is also a board member of Somnetics, a private company specializing in treatments for sleep apnea. At LifeSync, Bob drives the strategic direction of the company and works throughout the organization to support the company’s values and goal of making superior connections with patients.'
    },
    {
        bioname:'Ian West, PhD',
        biotitle:'VP of R&D, Quality, & Regulatory Affairs',
        biotitleHover:'VP of R&D, Quality, & Regulatory Affairs',
        headshot: HeadShotIanWBW,
        headshotAlt: 'Ian West, PhD - VP of R&D, Quality, & Regulatory Affairs',
        headshotHover: HeadShotIanWC,
        headshotHoverAlt: 'Ian West, PhD - VP of R&D, Quality, & Regulatory Affairs',
        bio:'Ian West is the Vice President of R&D, Quality, and Regulatory Affairs, overseeing LifeSync product innovation, design and compliance. Over the last twenty-five years, Ian held leadership positions developing patient monitoring and therapeutic devices at global companies including Johnson & Johnson, GE Healthcare and Philips Healthcare. At LifeSync, Ian is focused on creating innovative product solutions that exceed customer’s expectations for quality and reliability.'
    },
    {
        bioname:'Josh Stapleton',
        biotitle:'VP of Operations',
        biotitleHover:'VP of Operations',
        headshot: HeadShotJoshRBW,
        headshotAlt: 'Josh Stapleton - VP of Operations',
        headshotHover: HeadShotJoshSC,
        headshotHoverAlt: 'Josh Stapleton - VP of Operations',
        bio:'Josh Stapleton is the Vice President of Operations and Supply Chain. He joins LifeSync from ZimVie, a spinoff of Zimmer Biomet. He brings over 20 years of experience in the medical device space, focusing on lean manufacturing within orthopedics, spine, dental implants, and wound healing with global organizations such as Smith & Nephew, Biomet 3i, Zimmer Biomet, and ZimVie. At LifeSync, Josh is responsible for ensuring our team members focus on safety, quality, delivery, and cost to ensure our customers have the right products, at the right place, and at the right time.'
    },
    {
        bioname:'Bob Zinsmaster',
        biotitle:'VP of Sales & Customer Service',
        biotitleHover:'VP of Sales & Customer Service',
        headshot: HeadShotBobZBW,
        headshotAlt: 'Bob Zinsmaster - VP of Sales & Customer Service',
        headshotHover: HeadShotBobZC,
        headshotHoverAlt: 'Bob Zinsmaster - VP of Sales & Customer Service',
        bio:'Bob Zinsmaster is the Vice President of Sales and Customer Service, leading direct, contract manufacturing and key account sales as well as overseeing customer relations. Since 2000, Bob has held sales and executive leadership roles in medical device companies including Sheridan Catheter, Arizant Healthcare and 3M Healthcare. At LifeSync, Bob is focused on revenue growth through new business development opportunities and providing greater value and care to LifeSync customers.'
    },
    {
        bioname:'Pedro Tejeda',
        biotitle:'VP of Marketing and Product Management',
        biotitleHover:'VP of Marketing and Product Managmenet',
        headshot: HeadShotPedroTBW,
        headshotAlt: 'Pedro Tejeda - VP of Marketing and Product Management',
        headshotHover: HeadShotPedroTC,
        headshotHoverAlt: 'Pedro Tejeda - VP of Marketing and Product Management',
        bio:'Pedro Tejeda is the VP of Marketing, leading product management, corporate marketing, LOB communications, Digital channels and branding. For over 20 years, Pedro has brought his global marketing expertise to medical monitoring companies like Draeger Medical, Natus and Hill-Rom. At LifeSync, Pedro is focused on strategic segment growth and customer satisfaction for the organization, as well as targeting specific market segments, e-commerce, and geographic locations for business expansion. Pedro brings the voice of the customer and market trends and needs to the product development process and strategic OEM partnerships. Pedro\'s excellent strategic skills in business transformation guided LifeSync through its rebranding goal seamlessly.​'
    },
    {
        bioname:'Yuliya Varabei',
        biotitle:'Chief Financial Officer',
        biotitleHover:'Chief Financial Officer',
        headshot: HeadShotYuliaRBW,
        headshotAlt: 'Yuliya Varabei - Chief Financial Officer',
        headshotHover: HeadShotYuliaRC,
        headshotHoverAlt: 'Yuliya Varabei - Chief Financial Officer',
        bio:'Yuliya Varabei is the Chief Financial Officer, overseeing Finance, IT, and administrative functions. Yuliya holds 20 years of experience in public and private sectors including audit, accounting, financial planning and analysis, treasury, operations, supply chain, and procurement, IT, HR, legal, contracts and risk management, lender and investor relations, and mergers & acquisitions. Yuliya’s CFO and executive finance roles span from Ernst & Young LLC audit engagements for Citrix Systems and Spirit Airlines to executive finance roles with private equity owned businesses such as Volvo Aero Services, ACR Electronics, manufacturer of life safety and tracking devices, and SA Company, an e-commerce start-up retailer of outdoor apparel and gear.'
    }
];

const BioItem = props => {
    return (
        <div 
                className={
                "bioWrapper " + (props.itemNumber === props.itemselected ? "active" : "")
            }
        >
            <div className="bioImage">
                <Button className="hoverBio biobb">
                    <img src={props.headshot} alt={props.biotitle} width="130" className="normal" title={props.headshotAlt} loading="lazy" />
                    <img src={props.headshotHover} alt={props.biotitleHover} width="130" className="hoverColor" title={props.headshotHoverAlt} loading="lazy" />
                    <div className="icon">                            
                        <FaPlus className="normal" />
                        <FaMinus className="active" />
                    </div>
                </Button>
            </div>
            <p className="bioTitle">
                <span className="uc">{props.bioName}</span><br />
                {props.bioTitle}
            </p>
            <p className="bioMobile">{props.bio}</p>
        </div>
    );
};

const Details = props => {
    return (
      <Col xs={12} lg={{ span: 5, order: 2}} className="bioDetails">
        {props.itemselected === "" ? (
          <h2 className="intro">
            Our leadership team draws on broad industry experience to enable our customers to make better connections with patients and to make a difference for our other stakeholders: patients, communities and employees.       
          </h2>
        ) : (
          <div className="bioinfo">
            <h3>{props.biosObject[props.itemselected].bioname}</h3>
            <div className="biodetailsbox">
                <p>{props.biosObject[props.itemselected].bio}</p>
            </div>
          </div>
        )}
      </Col>
    );
  };

const HomeBios = (props) => {
    const [activeIndex, setActiveIndex] = useState({
        activeIndex: ""
    });
    function handleClick(i) {
        if (i === activeIndex.activeIndex) {
            setActiveIndex({ activeIndex: "" });
        } else {
            setActiveIndex({ activeIndex: i });
        }
    }
    return (
        <div className="homeBios">
            <Container>
                <Row>
                    <Col className="rowBorder">
                        <Row>
                            <Details biosObject={bios} itemselected={activeIndex.activeIndex} />
                            <Col xs={12} lg={7} className="bioImages">
                                <Row className="sibling-fade">
                                    {bios.map((e,i) => {
                                        return(
                                            <Col 
                                                className="ListItemWrap" 
                                                key={i} 
                                                xs={12} 
                                                lg={4} 
                                                onClick={() => handleClick(i)}
                                            >
                                                <BioItem 
                                                    bioName={bios[i].bioname} 
                                                    bioTitle={bios[i].biotitle} 
                                                    bioTitleHover={bios[i].biotitleHover} 
                                                    headshot={bios[i].headshot} 
                                                    headshotAlt={bios[i].headshotAlt}
                                                    headshotHover={bios[i].headshotHover} 
                                                    headshotHoverAlt={bios[i].headshotHoverAlt}
                                                    bio={bios[i].bio}
                                                    itemNumber={i}
                                                    itemselected={activeIndex.activeIndex}
                                            />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.38 17.79" className="bgSvg"><title>full-color-iconAsset 17</title><g id="Layer_2" data-name="Layer 2"><g id="Page_03" data-name="Page 03"><polygon className="cls-1" points="16.97 10.49 9.69 17.77 3.16 11.24 3.92 10.48 9.69 16.26 15.47 10.47 16.97 10.49 16.97 10.49"/><polygon className="cls-1" points="16.97 10.46 9.69 17.73 3.16 11.2 3.92 10.44 9.69 16.22 15.47 10.44 16.97 10.45 16.97 10.46"/><polygon className="cls-1" points="16.97 10.51 9.69 17.79 3.16 11.26 3.92 10.5 9.69 16.27 15.47 10.49 16.97 10.51 16.97 10.51"/><path className="cls-2" d="M1.66,9.7a5.68,5.68,0,0,1,4-9.7A5.64,5.64,0,0,1,8.86,1l-.77.77A4.62,4.62,0,0,0,2.4,8.94L9.69,1.65h0A5.69,5.69,0,0,1,18,9.41c-1.54,0-3.1,0-4.65,0l-1-3.71L9.91,14.17,8.57,7.94,7,9.54H4.82l1-1h.75L9.18,6l.93,4.27L12.38,2l1.73,6.42,3.3,0a4.67,4.67,0,0,0,.9-2.76,4.61,4.61,0,0,0-7.87-3.26l-8,8Z"/></g></g></svg>
        </div>
    );
};

export default HomeBios