import React from "react"
import PropTypes from "prop-types"
import { Parallax } from 'react-parallax'

import MainNavBar from "../mainNavBar"
import CaptionHome from "./captionHome"
import HomeHeroNav from "./homeHeroNav"

const HeaderHome = ({ siteTitleHome }) => (
  <Parallax
      bgImage={require('../../images/hero-home-p.jpg')}
      bgImageAlt="LifeSync"
      bgImageTitle="LifeSync"
      strength="150"
  >
    <header className="headerHome">
      <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
      <MainNavBar />
      <CaptionHome  />
      <HomeHeroNav />
    </header>
  </Parallax>
)

HeaderHome.propTypes = {
  siteTitleHome: PropTypes.string,
}

HeaderHome.defaultProps = {
  siteTitleHome: ``,
}

export default HeaderHome
