import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax';

const HomeTwoColCopyRight = () => (
  <div className="homeTwoColCopyRight">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 2}} className="twoColCopyRightCopy">
                <Row>
                    <Col>
                        <div className="copyRightWrap">
                            <h2>Patient connection specialists</h2>
                            <p>LifeSync focuses exclusively on patient monitoring connections, bringing you superior performance, reliability, and patient care.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/ls-patient-connection.jpg')}
                    bgImageAlt="LifeSync Patient Connection"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default HomeTwoColCopyRight
